

export const valoresPlanosSischef = {

    logo: "/images/logo-sischef-256.png",


    tituloPrincipal: "Sischef",
    tituloSecundario: "Monte o plano perfeito para você",

    descricao: "Calculadora de planos Sischef",
    grupos: [
        {
            id: "GRUPO_PRINCIPAL",
            descricao: "Módulos principais",

            modulos: [
                {
                    id: "START",
                    descricao: "Plano Essencial",
                    valorAtivacao: 300.00,
                    valorMensalidade: 49.95,
                    selecionado: true,
                    horas: 2,
                    bloqueado: true,
                    modulosInternos: [
                        {
                            id: "Cadastro pessoas",
                            descricao: "Cadastro pessoas",
                            valorAtivacao: 0.0,
                            valorMensalidade: 0.0,
                            selecionado: true,
                            bloqueado: true,
                        },
                        {
                            id: "Cadastro de produtos",
                            descricao: "Cadastro de produtos",
                            valorAtivacao: 0.0,
                            valorMensalidade: 0.0,
                            selecionado: true,
                            bloqueado: true,
                        },

                        {
                            id: "Cadastro de grupos",
                            descricao: "Cadastro de grupos",
                            valorAtivacao: 0.0,
                            valorMensalidade: 0.0,
                            selecionado: true,
                            bloqueado: true,
                        },
                        {
                            id: "Primeiro cadastro do cardápio",
                            descricao: "Primeiro cadastro do cardápio",
                            valorAtivacao: 0.0,
                            valorMensalidade: 0.0,
                            selecionado: true,
                            bloqueado: true,
                        },
                        {
                            id: "Configurações iniciais",
                            descricao: "Configurações iniciais",
                            valorAtivacao: 0.0,
                            valorMensalidade: 0.0,
                            selecionado: true,
                            bloqueado: true,
                        },
                    ]
                },

                {
                    id: "PDV Online",
                    descricao: "PDV Online",
                    valorAtivacao: 0,
                    valorMensalidade: 24.95,
                    selecionado: false,
                    horas: 3,
                    modulosInternos: [
                        {
                            id: "Pedido de comanda e Mesa",
                            descricao: "Pedido de comanda e Mesa",
                            valorAtivacao: 150.0,
                            valorMensalidade: 24.95,
                            selecionado: false,

                        },
                        {
                            id: "Pedido delivery + balcão",
                            descricao: "Pedido delivery + balcão + Gestão de entregadores",
                            valorAtivacao: 150.0,
                            valorMensalidade: 24.95,
                            selecionado: false,
                        },
                        {
                            id: "Pedido Venda rápida",
                            descricao: "Pedido Venda rápida",
                            valorAtivacao: 150.0,
                            valorMensalidade: 24.95,
                            selecionado: false,
                        },
                        {
                            id: "Pedidos Agendados",
                            descricao: "Pedidos Agendados",
                            valorAtivacao: 75.0,
                            valorMensalidade: 19.9,
                            selecionado: false,
                        },
                        {
                            id: "Mapa de calor",
                            descricao: "Mapa de calor",
                            valorAtivacao: 150.00,
                            valorMensalidade: 19.9,
                            selecionado: false,
                        },

                        {
                            id: "Pedidos Smartphone / Tablet",
                            descricao: "Pedidos Smartphone / Tablet",
                            valorAtivacao: 150.0,
                            valorMensalidade: 29.9,
                            selecionado: false,
                            horas: 1,
                        },
                        {
                            id: "SmartPOS (Cielo Lio)",
                            descricao: "SmartPOS (Cielo Lio)",
                            valorAtivacao: 150.0,
                            valorMensalidade: 49.9,
                            selecionado: false,
                            horas: 1,
                        },
                        {
                            id: "SmartPOS (STONE)",
                            descricao: "SmartPOS (STONE)",
                            valorAtivacao: 150.0,
                            valorMensalidade: 49.9,
                            selecionado: false,
                            horas: 1,
                        },
                        {
                            id: "IntegracaoAutoAtendedimentoBalancaOnLine",
                            descricao: "AutoAtendimento Balança",
                            valorAtivacao: 150.0,
                            valorMensalidade: 39.9,
                            selecionado: false,
                            horas: 1,
                        },
                    ]
                },
                {
                    id: "PDV Offline",
                    descricao: "PDV Offline",
                    valorAtivacao: 199.90,
                    valorMensalidade: 24.95,
                    horas: 3,
                    selecionado: false,
                    modulosInternos: [
                        {
                            id: "Pedido de comanda e Mesa ou Sequencial",
                            descricao: "Pedido de comanda e Mesa ou Sequencial",
                            valorAtivacao: 75.0,
                            valorMensalidade: 24.95,
                            selecionado: false,
                        },
                        {
                            id: "IntegracaoAutoAtendedimentoBalancaOff",
                            descricao: "AutoAtendimento Balança",
                            valorAtivacao: 150.0,
                            valorMensalidade: 39.9,
                            selecionado: false,
                            horas: 1,
                        },
                        {
                            id: "Delivery + Gestão de entregas",
                            descricao: "Delivery - Gestão de entregas",
                            valorAtivacao: 150.0,
                            valorMensalidade: 19.9,
                            selecionado: false,
                            horas: 1,
                        },
                        {
                            id: "Pedidos Smartphone / Tablet",
                            descricao: "Pedidos Smartphone / Tablet",
                            valorAtivacao: 150.0,
                            valorMensalidade: 29.9,
                            selecionado: false,
                            horas: 1,
                        },
                    ]
                },
                {
                    id: "NOTIFICACAO",
                    descricao: "Notificação nas áreas de produção ( Incluso 2 áreas de notificação )",
                    valorAtivacao: 75.0,
                    valorMensalidade: 0.0,
                    selecionado: false,
                    modulosInternos: [

                        {
                            id: "Áreas de notificação",
                            descricao: "Área de notificação adicional",
                            valorAtivacao: 24.90,
                            valorMensalidade: 9.90,
                            selecionado: false,
                            quantidade: 0,
                            defaultQuantidade: 0,
                            horas: 1,
                            tipo: "NUMERO",
                        },

                        {
                            id: "KDS",
                            descricao: "Em tela KDS",
                            valorAtivacao: 75.00,
                            valorMensalidade: 39.90,
                            selecionado: false,
                        },

                        {
                            id: "IMPRESSO",
                            descricao: "Em Impressora",
                            valorAtivacao: 75.00,
                            valorMensalidade: 19.90,
                            selecionado: false,
                            selecionadoDefault: true,
                        },
                    ]
                },

                {
                    id: "Gerenciamentodeentregas",
                    descricao: "Gerenciamento de entregas",
                    valorAtivacao: 0.00,
                    valorMensalidade: 0.00,
                    selecionado: false,
                    modulosInternos: [
                        {
                            id: "Despacho de pedido automatizado",
                            descricao: "Despacho de pedido automatizado",
                            valorAtivacao: 75.0,
                            valorMensalidade: 39.90,
                            selecionado: false,
                            selecionadoDefault: true,
                            bloqueado: false,
                        },
                        {
                            id: "Acerto com o entregadores",
                            descricao: "Tela de acerto com entregadores",
                            valorAtivacao: 75.0,
                            valorMensalidade: 19.90,
                            selecionado: false,
                            selecionadoDefault: true,
                            bloqueado: false,
                        },
                    ]
                },
                {
                    id: "ESTOQUE",
                    descricao: "Estoque",
                    valorAtivacao: 150.0,
                    valorMensalidade: 19.9,
                    selecionado: false,
                    modulosInternos: [
                        {
                            id: "Produção de receitas",
                            descricao: "Produção de receitas",
                            valorAtivacao: 150.0,
                            valorMensalidade: 29.9,
                            selecionado: false,
                            horas: 1,
                        },
                        {
                            id: "Locais de Estoque",
                            descricao: "Locais de Estoque",
                            valorAtivacao: 150.0,
                            valorMensalidade: 29.9,
                            selecionado: false,
                            horas: 1,
                        },
                        {
                            id: "Composição produtos",
                            descricao: "Composição produtos",
                            valorAtivacao: 75.0,
                            valorMensalidade: 19.9,
                            selecionado: false,
                        },
                        {
                            id: "Importação XML - Busca SEFAZ",
                            descricao: "Entrada de mercadoria por XML (por chave e busca automática)",
                            valorAtivacao: 150.0,
                            valorMensalidade: 19.9,
                            selecionado: false,
                        },
                    ]
                },
                {
                    id: "FINANCEIRO",
                    descricao: "Financeiro (Incluso 1 Controle de Caixa) ",
                    valorAtivacao: 150.0,
                    valorMensalidade: 29.9,
                    selecionado: false,
                    modulosInternos: [
                        {
                            id: "Controle de Caixa",
                            descricao: "Controle de Caixa adicional",
                            subTitulo: 'Cobrado por operador',
                            valorAtivacao: 75.0,
                            valorMensalidade: 9.90,
                            horas: 1,
                            tipo: "NUMERO",
                            defaultQuantidade: 0,
                            quantidade: 0,
                            selecionado: false,
                        },
                        {
                            id: "Receitas/Despesas Recorrentes",
                            descricao: "Receitas/Despesas Recorrentes",
                            valorAtivacao: 150.0,
                            valorMensalidade: 19.9,
                            selecionado: false,
                            horas: 1,
                        },
                        {
                            id: "Controle Cartões (Credito/Debito)",
                            descricao: "Controle Cartões (Credito/Debito)",
                            valorAtivacao: 75.0,
                            valorMensalidade: 19.90,
                            selecionado: false,
                        },
                        {
                            id: "Limite de crédito",
                            descricao: "Limite de crédito",
                            valorAtivacao: 75.0,
                            valorMensalidade: 29.9,
                            selecionado: false,
                        },
                        {
                            id: "Crédito Pre pago",
                            descricao: "Crédito Pré Pago",
                            valorAtivacao: 150.0,
                            valorMensalidade: 29.9,
                            selecionado: false,
                        },
                    ]
                },
                {
                    id: "INTEGRACAOTEFPAYGO",
                    descricao: "Integração TEF Pay Go",
                    valorAtivacao: 150.0,
                    valorMensalidade: 0.0,
                    selecionado: false,
                    modulosInternos: [
                        {
                            id: "IntegracaoTEFPDVONLINE",
                            descricao: "TEF PDV online",
                            valorAtivacao: 150.0,
                            valorMensalidade: 99.90,
                            selecionado: false,
                            quantidade: 0,
                            defaultQuantidade: 0,
                            tipo: "NUMERO",
                        },
                        {
                            id: "IntegracaoTEFPDVOFFOLINE",
                            descricao: "TEF PDV offline",
                            valorAtivacao: 150.0,
                            valorMensalidade: 99.90,
                            selecionado: false,
                            defaultQuantidade: 0,
                            quantidade: 0,
                            tipo: "NUMERO",
                        },
                    ]
                },
                {
                    id: "INTEGRACAOTEFELGIN",
                    descricao: "Integração TEF Elgin",
                    valorAtivacao: 100.00,
                    valorMensalidade: 100.00,
                    selecionado: false,
                    modulosInternos: [
                        {
                            id: "IntegracaoTEFPDVONLINE",
                            descricao: "TEF PDV online",
                            valorAtivacao: 100.00,
                            valorMensalidade: 49.90,
                            selecionado: false,
                            defaultQuantidade: 1,
                            quantidade: 0,
                            tipo: "NUMERO",
                        }
                    ]
                },
                {
                    id: "WHATSAPPCONNECT",
                    descricao: "Whatsapp Connect",
                    valorAtivacao: 0.00,
                    valorMensalidade: 0.00,
                    selecionado: false,
                    modulosInternos: [
                        {
                            id: "WhatsAppConnect",
                            descricao: "Notificação de alteração de status dos pedidos",
                            valorAtivacao: 150.0,
                            valorMensalidade: 49.0,
                            selecionado: false,
                            selecionadoDefault: true,
                            bloqueado: true,
                        },
                    ]
                },
                {
                    id: "FISCAL",
                    descricao: "Fiscal",
                    valorAtivacao: 0.0,
                    valorMensalidade: 0.0,
                    selecionado: false,
                    modulosInternos: [
                        {
                            id: "Emissor de Nfce e Nfe - Simples Nacional",
                            descricao: "Emissor de Nfce e Nfe - Simples Nacional",
                            valorAtivacao: 300.0,
                            valorMensalidade: 49.9,
                            selecionado: false,
                            horas: 1,
                        },
                        {
                            id: "Emissor de Nfce e Nfe - Regime Normal",
                            descricao: "Emissor de Nfce e Nfe - Regime Normal",
                            valorAtivacao: 450.0,
                            valorMensalidade: 99.9,
                            selecionado: false,
                            horas: 1,
                        },
                        {
                            id: "Emissor de cupom fiscal e nfe - Fiscal SC",
                            descricao: "Emissor de cupom fiscal e nfe - Fiscal SC",
                            valorAtivacao: 450.0,
                            valorMensalidade: 120.0,
                            selecionado: false,
                            horas: 1,
                        },
                        {
                            id: "Usuario contador",
                            descricao: "Usuario contador",
                            valorAtivacao: 75.00,
                            valorMensalidade: 14.90,
                            selecionado: false,
                        },
                        {
                            id: "MyTappEmissaoFiscalGerencial",
                            descricao: "MyTapp Emissão fiscal",
                            valorAtivacao: 150.0,
                            valorMensalidade: 49.90,
                            selecionado: false,
                        }
                    ]
                },
                {
                    id: "Integrações APPs ( PDV Online )",
                    descricao: "Integrações APPs ( PDV Online )",
                    valorAtivacao: 75.0,
                    valorMensalidade: 24.95,
                    selecionado: false,
                    horas: 1,
                    modulosInternos: [
                        {
                            id: "Qrpedir",
                            descricao: "Integração Qrpedir",
                            subTitulo: "Grátis",
                            valorAtivacao: 0.0,
                            valorMensalidade: 0.0,
                            selecionado: false,
                        },
                        {
                            id: "iFood",
                            descricao: "iFood",
                            valorAtivacao: 75.0,
                            tipo: "NUMERO",
                            quantidade: 0,
                            valorMensalidade: 24.95,
                            selecionado: false,
                        },
                        {
                            id: "Rappi",
                            descricao: "Rappi",
                            valorAtivacao: 75.0,
                            valorMensalidade: 24.95,
                            selecionado: false,
                            tipo: "NUMERO",
                            quantidade: 0,
                        },
                        {
                            id: "Neemo",
                            descricao: "Neemo",
                            valorAtivacao: 75.0,
                            valorMensalidade: 24.95,
                            selecionado: false,
                            tipo: "NUMERO",
                            quantidade: 0,
                        },
                        {
                            id: "Delivery Direto",
                            descricao: "Delivery Direto",
                            valorAtivacao: 75.0,
                            valorMensalidade: 24.95,
                            selecionado: false,
                            tipo: "NUMERO",
                            quantidade: 0,
                        },
                        {
                            id: "Accon",
                            descricao: "Accon",
                            valorAtivacao: 75.0,
                            valorMensalidade: 24.95,
                            selecionado: false,
                            tipo: "NUMERO",
                            quantidade: 0,
                        },
                        {
                            id: "PedZapDelivery",
                            descricao: "PedZap Delivery",
                            valorAtivacao: 75.0,
                            valorMensalidade: 24.95,
                            selecionado: false,
                            tipo: "NUMERO",
                            quantidade: 0,
                        },
                        {
                            id: "PedZapMesa",
                            descricao: "PedZap Mesa",
                            valorAtivacao: 75.0,
                            valorMensalidade: 24.95,
                            selecionado: false,
                            tipo: "NUMERO",
                            quantidade: 0,
                        },
                        {
                            id: "DeliveryMuch",
                            descricao: "Delivery Much",
                            valorAtivacao: 75.0,
                            valorMensalidade: 24.95,
                            selecionado: false,
                            tipo: "NUMERO",
                            quantidade: 0,
                        },
                        {
                            id: "AnotaAIDelivery",
                            descricao: "Anota Aí Delivery",
                            valorAtivacao: 75.0,
                            valorMensalidade: 24.95,
                            selecionado: false,
                            tipo: "NUMERO",
                            quantidade: 0,
                        },
                        {
                            id: "AnotaAIMesa",
                            descricao: "Anota Aí Mesa",
                            valorAtivacao: 75.0,
                            valorMensalidade: 24.95,
                            selecionado: false,
                            tipo: "NUMERO",
                            quantidade: 0,
                        },
                        {
                            id: "DeLivre",
                            descricao: "DeLivre / Cardapius",
                            valorAtivacao: 75.0,
                            valorMensalidade: 24.95,
                            selecionado: false,
                            tipo: "NUMERO",
                            quantidade: 0,
                        },
                        {
                            id: "Aiqfome",
                            descricao: "Aiqfome",
                            valorAtivacao: 75.0,
                            valorMensalidade: 24.95,
                            selecionado: false,
                            tipo: "NUMERO",
                            quantidade: 0,
                        },
                        {
                            id: "Goomer Delivery",
                            descricao: "Goomer Delivery",
                            valorAtivacao: 75.0,
                            valorMensalidade: 24.95,
                            selecionado: false,
                            tipo: "NUMERO",
                            quantidade: 0,
                        },
                        {
                            id: "Goomer QrCode",
                            descricao: "Goomer QrCode",
                            valorAtivacao: 75.0,
                            valorMensalidade: 24.95,
                            selecionado: false,
                            tipo: "NUMERO",
                            quantidade: 0,
                        },
                        {
                            id: "Goomer Tablet",
                            descricao: "Goomer Tablet",
                            valorAtivacao: 75.0,
                            valorMensalidade: 24.95,
                            selecionado: false,
                            tipo: "NUMERO",
                            quantidade: 0,
                        },
                        {
                            id: "Goomer Toten",
                            descricao: "Goomer Auto Atendimento",
                            valorAtivacao: 75.0,
                            valorMensalidade: 24.95,
                            selecionado: false,
                            tipo: "NUMERO",
                            quantidade: 0,
                        },
                        {
                            id: "Cardápio Web",
                            descricao: "Cardápio Web",
                            valorAtivacao: 75.0,
                            valorMensalidade: 24.95,
                            selecionado: false,
                            tipo: "NUMERO",
                            quantidade: 0,
                        },
                        {
                            id: "Abrahão",
                            descricao: "Abrahão",
                            valorAtivacao: 75.0,
                            valorMensalidade: 24.95,
                            selecionado: false,
                        },
                        {
                            id: "PedeAi",
                            descricao: "Pede Ai",
                            valorAtivacao: 75.0,
                            valorMensalidade: 24.95,
                            selecionado: false,
                        },
                        {
                            id: "MultiPedidos",
                            descricao: "Multi Pedidos",
                            valorAtivacao: 75.0,
                            valorMensalidade: 24.95,
                            selecionado: false,
                        },
                        // {
                        //     id: "outrosAPPs",
                        //     descricao: "Outros Apps",
                        //     valorAtivacao: 75.0,
                        //     valorMensalidade: 24.95,
                        //     selecionado: false,
                        //     tipo: "NUMERO",
                        //     quantidade: 0,
                        // },
                        {
                            id: "Vinculo-produtos",
                            descricao: "Associação dos produtos",
                            subTitulo: "Valor por aplicativo",
                            valorAtivacao: 150.0,
                            valorMensalidade: 0.00,
                            selecionado: false,
                            quantidade: 0,
                            tipo: "NUMERO",
                        },
                    ]
                },

                {
                    id: "Integrações app de entregas ( PDV Online )",
                    descricao: "Integrações APPs de entregas ( PDV Online )",
                    valorAtivacao: 75.0,
                    valorMensalidade: 24.95,
                    selecionado: false,
                    modulosInternos: [
                        {
                            id: "Foodydelivery",
                            descricao: "Foody Delivery",
                            valorAtivacao: 75.0,
                            valorMensalidade: 24.95,
                            selecionado: false,
                        },
                        {
                            id: "Kartero",
                            descricao: "Kartero",
                            valorAtivacao: 75.0,
                            valorMensalidade: 24.95,
                            selecionado: false,
                        },
                    ]
                },
                {
                    id: "Integrações Catraca",
                    descricao: "Integrações com catraca",
                    valorAtivacao: 0.0,
                    valorMensalidade: 0.00,
                    selecionado: false,
                    modulosInternos: [
                        {
                            id: "Catraca",
                            descricao: "Integração com catraca por troca de arquivo XML",
                            valorAtivacao: 300.0,
                            valorMensalidade: 49.90,
                            selecionado: false,
                            selecionadoDefault: true,
                            bloqueado: true,
                        },
                    ]
                },

                {
                    id: "Apps de fidelidade",
                    descricao: "Integração APPs de fidelidade",
                    valorAtivacao: 0.0,
                    valorMensalidade: 0.0,
                    selecionado: false,
                    modulosInternos: [
                        {
                            id: "Fidelimax",
                            descricao: "Fidelimax - Programa de fidelidade",
                            valorAtivacao: 150.0,
                            valorMensalidade: 49.90,
                            selecionado: false,
                        },
                        {
                            id: "TemBonus",
                            descricao: "Tem Bônus - Programa de fidelidade",
                            valorAtivacao: 150.0,
                            valorMensalidade: 49.90,
                            selecionado: false,
                        },
                        {
                            id: "Repediu",
                            descricao: "Repediu - Programa de fidelidade",
                            valorAtivacao: 150.0,
                            valorMensalidade: 49.90,
                            selecionado: false,
                        }
                    ]
                },

                {
                    id: "INTEGRACAO_TOTEN",
                    descricao: "Integração Toten auto-atendimento",
                    valorAtivacao: 75.0,
                    valorMensalidade: 0.0,
                    selecionado: false,
                    modulosInternos: [
                        {
                            id: "iFood",
                            descricao: "Toten iFood",
                            valorAtivacao: 150.0,
                            valorMensalidade: 75.00,
                            selecionado: false,
                            quantidade: 0,
                            defaultQuantidade: 0,
                            tipo: "NUMERO",
                        },
                        {
                            id: "Go Toten",
                            descricao: "Go Toten",
                            valorAtivacao: 75.0,
                            valorMensalidade: 49.90,
                            selecionado: false,
                            defaultQuantidade: 0,
                            quantidade: 0,
                            tipo: "NUMERO",
                        },
                    ]
                },

                {
                    id: "Sischef Analytics",
                    descricao: "Sischef Analytics Premium",
                    valorAtivacao: 0.0,
                    valorMensalidade: 0.0,
                    selecionado: false,
                    modulosInternos: [
                        {
                            id: "Analytics",
                            descricao: "Unidades / lojas",
                            valorAtivacao: 150.0,
                            valorMensalidade: 49.90,
                            selecionado: false,
                            quantidade: 0,
                            tipo: "NUMERO",
                        }
                    ]
                },
            ]
        },

        {
            id: "GRUPO_EXTRAS",
            descricao: "Módulos extras",
            modulos: [
                {
                    id: "Outros",
                    descricao: "Serviços adicionais",
                    valorAtivacao: 0.0,
                    valorMensalidade: 0.0,
                    selecionado: false,
                    modulosInternos: [

                        // {
                        //     id: "Treinamento",
                        //     descricao: "Horas de treinamento - Valor/hora",
                        //     valorAtivacao: 150.0,
                        //     valorMensalidade: 0.00,
                        //     quantidade: 0,
                        //     tipo: "NUMERO",
                        // },
                        {
                            id: "Relatorios Personalizados Excel hora",
                            descricao: "Relatorios Personalizados Excel - Valor/hora",
                            valorAtivacao: 150.0,
                            valorMensalidade: 0.00,
                            quantidade: 0,
                            tipo: "NUMERO",
                        },
                        // {
                        //     id: "Ativação Sat",
                        //     descricao: "Ativação Sat",
                        //     valorAtivacao: 300.0,
                        //     valorMensalidade: 0.00,
                        //     selecionado: false,
                        // },
                        {
                            id: "Vinculo-produtos",
                            descricao: "Associação dos produtos",
                            subTitulo: "Valor por aplicativo",
                            valorAtivacao: 150.0,
                            valorMensalidade: 0.00,
                            quantidade: 0,
                            tipo: "NUMERO",
                        },
                        {
                            id: "Importação Pessoas cada 1000",
                            descricao: "Importação Pessoas (por 1000 registros)",
                            valorAtivacao: 150.0,
                            valorMensalidade: 0.00,
                            tipo: "NUMERO",
                            quantidade: 0,
                        },
                        {
                            id: "Importação Produtos cada 500",
                            descricao: "Importação Produtos (por 500 registros)",
                            valorAtivacao: 150.0,
                            valorMensalidade: 0.00,
                            tipo: "NUMERO",
                            quantidade: 0,
                        },

                    ]
                },
                {
                    id: "Integrações ( PDV Off )",
                    descricao: "Integrações ( PDV Off )",
                    valorAtivacao: 0.0,
                    valorMensalidade: 0.0,
                    selecionado: false,
                    modulosInternos: [

                        {
                            id: "MyTappIntegracaoLocal",
                            descricao: "MyTapp Integração local",
                            valorAtivacao: 150.0,
                            valorMensalidade: 49.90,
                            selecionado: false,
                        },
                        {
                            id: "OiMenu",
                            descricao: "OiMenu PDV Off-line",
                            valorAtivacao: 150.0,
                            valorMensalidade: 49.90,
                            selecionado: false,
                        }
                    ]

                },
                {
                    id: "cardapioDigital",
                    descricao: "Cardápio Digital Qrpedir",
                    valorAtivacao: 0,
                    valorMensalidade: 0,
                    selecionado: false,
                    modulosInternos: [
                        {
                            id: "QrpedirMesa",
                            descricao: "Qrpedir mesa",
                            valorAtivacao: 300.0,
                            valorMensalidade: 79.90,
                            selecionado: false,
                            quantidade: 0,
                            tipo: "NUMERO",
                        },
                        {
                            id: "QrpedirDelivery",
                            descricao: "Qrpedir Delivery",
                            valorAtivacao: 300.0,
                            valorMensalidade: 79.90,
                            selecionado: false,
                            quantidade: 0,
                            tipo: "NUMERO",
                        },
                        {
                            id: "CavaleteMesa",
                            descricao: "Cavaletes para mesa com qrcode",
                            valorAtivacao: 25.00,
                            valorMensalidade: 0.0,
                            valorFrete: 100.00,
                            quantidade: 0,
                            tipo: "NUMERO",
                        },
                    ]
                },
            ]
        }
    ]

}