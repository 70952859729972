import React, { useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Divider, Grid, List, ListItem } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { useHistory } from "react-router-dom";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import Footer from "../components/Footer";
import PasswordModal from '../components/dialog';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
}));

export default function Main() {
  const classes = useStyles();
  const history = useHistory();
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [passwordDestination, setPasswordDestination] = useState(null);

  const handleCalculadoraClick = (path) => {
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    if (path === "/calculadora/sischef" || path === "/calculadora/qrpedir" || path === "/contrato") {
      if (!isLoggedIn) {
        setPasswordDestination(path);
        setIsPasswordModalOpen(true);
      } else {
        history.push(path);
      }
    }
  };

  const handlePasswordSubmit = (destination) => {
    localStorage.setItem('isLoggedIn', 'true');
    history.push(destination);
    setIsPasswordModalOpen(false);
    setPasswordDestination('');
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Container component="main" className={classes.main} maxWidth="sm">
        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
          Parseint Sistemas
        </Typography>
        <Typography variant="h5" align="center" component="h2" gutterBottom>
          Selecione a opção desejada
        </Typography>
        <Typography variant="body1"></Typography>

        <Grid container spacing={3}>
          <Grid item xs={12}>

            <List>
              <ListItem button onClick={() => handleCalculadoraClick("/calculadora/sischef")}>
                <ListItemIcon>
                  <LocalAtmIcon />
                </ListItemIcon>
                <ListItemText primary="Calculadora de preços - Sischef" />
              </ListItem>

              <ListItem button onClick={() => handleCalculadoraClick("/calculadora/qrpedir")}>
                <ListItemIcon>
                  <LocalAtmIcon />
                </ListItemIcon>
                <ListItemText primary="Calculadora de preços - qrpedir" />
              </ListItem>

              <ListItem button onClick={() => handleCalculadoraClick("/contrato")}>
                <ListItemIcon>
                  <AssignmentTurnedInIcon />
                </ListItemIcon>
                <ListItemText primary="Contrato" />
              </ListItem>

              <Divider />
              <ListItem button onClick={() => history.push("/cadastro/sischef")}>
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary="Cadastro Sischef" />
              </ListItem>
              
              <ListItem button onClick={() => history.push("/cadastro/qrpedir")}>
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary="Cadastro Qrpedir" />
              </ListItem>
             
              <ListItem button onClick={() => history.push("/cadastro/sisagil")}>
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary="Cadastro Sisagil" />
              </ListItem>
            </List>
          </Grid>
        </Grid>

      </Container>

      <Footer />

      <PasswordModal
        open={isPasswordModalOpen}
        onClose={() => setIsPasswordModalOpen(false)}
        onPasswordSubmit={handlePasswordSubmit}
        destination={passwordDestination}
      />

    </div>
  );
}