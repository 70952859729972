import React from 'react';
import Routes from './routes';
import { SnackbarProvider } from 'notistack';
import theme from './theme/theme';
import { ThemeProvider } from '@material-ui/styles';


function App() {

  return (
    <div className="App">
    <ThemeProvider theme={theme}>
        <SnackbarProvider 
          maxSnack={3}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}>
          <Routes />
        </SnackbarProvider>
        </ThemeProvider>
    </div> 
  )
}


export default App;
