import { createTheme } from "@material-ui/core";

// Definição do tema com cores primária e secundária
const theme = createTheme({
  palette: {
    primary: {
      main: '#e91e63',  // Azul padrão do Material-UI (altere para a cor desejada)
    },
    secondary: {
      main: '#ff1744',  // Laranja padrão do Material-UI (altere para a cor desejada)
    },
  } 
});

export default theme;